// @file Slideshow store
import { getSearchParam, isSearchParamTruthy } from '@@/bits/location'
import { getVuexStore } from '@@/bits/pinia'
import { getNumberOfWords } from '@@/bits/post_processing'
import type { AutoplayDurationType } from '@@/bits/slideshow'
import { buildSlideshowLink, calculateAutoSlideDuration, COVER_PAGE_INDEX, getCurrentPostCid } from '@@/bits/slideshow'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfaceSectionsStore } from '@@/pinia/surface_sections'
import type { Cid } from '@@/types'
import type { Page } from '@@/types/slideshow'
import { PageType } from '@@/types/slideshow'
import type { RootState } from '@@/vuexstore/surface/types'
import { isEmpty } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useSlideshowStore = defineStore('slideshow', () => {
  const surfaceSectionsStore = useSurfaceSectionsStore()
  const surfacePostsStore = useSurfacePostsStore()
  const surfaceStore = useSurfaceStore()

  /*
   * DATA
   */

  const hasLoadedPages = ref(false)
  const isDataLoaded = computed(() => surfaceSectionsStore.hasFetchedSections && surfacePostsStore.hasFetchedPosts)
  const pageCoverLoaded = ref(false)
  const isPageCoverLoaded = computed(() => {
    if (currentPage.value?.pageType !== PageType.Cover) return true
    if (shouldRemoveBackground.value) return true
    return pageCoverLoaded.value
  })

  /*
   * CUSTOM STYLES
   */

  const shouldRemoveBackground = computed(() => {
    const shouldRemoveBackground = getSearchParam('remove_background')
    return isSearchParamTruthy(shouldRemoveBackground)
  })

  /*
   * MENUS
   */

  const isMoreMenuOpen = ref(false)
  const isAutoplayMenuOpen = ref(false)
  const isAnyMenuOpen = computed(() => isMoreMenuOpen.value || isAutoplayMenuOpen.value)

  /*
   * PANELS
   */

  const isSharePanelOpen = ref(false)
  const isShareViaAnotherAppPanelOpen = ref(false)
  const isEmbedPanelOpen = ref(false)
  const isAnyPanelOpen = computed(
    () => isSharePanelOpen.value || isShareViaAnotherAppPanelOpen.value || isEmbedPanelOpen.value,
  )
  const isAnyOverlayOpen = computed(() => isAnyMenuOpen.value || isAnyPanelOpen.value)

  /*
   * AUTOPLAY
   */

  const isAutoplaying = ref(false)
  const isAutoplayPaused = ref(false)
  const isAutoplayLooping = ref(false)
  const selectedAutoplayDuration = ref<AutoplayDurationType>('auto')
  const computedAutoplayDurationInMs = computed(() => {
    if (selectedAutoplayDuration.value === 'auto') {
      return calculateAutoSlideDuration(numberOfWordsOnPage.value, numberOfAttachmentsOnPage.value)
    }
    return selectedAutoplayDuration.value * 1000
  })

  /*
   * QR CODE
   */

  const isQrCodeShown = ref(true)

  /*
   * PAGES
   */

  const currentPageIndex = ref<number>(0)

  const pages = computed(() => {
    // Prevent pages from being populated when not all data is available yet
    if (!isDataLoaded.value) {
      return []
    }

    // Populate pages from posts and/or sections
    const populatedPages: Page[] = [
      {
        pageType: PageType.Cover,
      },
    ]

    const pushSectionPage = (sectionId: number | string): void => {
      populatedPages.push({
        pageType: PageType.Section,
        entityId: sectionId.toString(),
      })
    }
    const pushPostPage = (postCid: string): void => {
      // Only approved posts should be shown
      if (surfacePostsStore.postEntitiesByCid[postCid].published !== true) {
        return
      }

      populatedPages.push({
        pageType: PageType.Post,
        entityId: postCid?.replace('c', ''),
      })
    }

    const hasPosts = !isEmpty(surfacePostsStore.sortedPostCids)

    if (surfaceSectionsStore.wallHasSections && hasPosts) {
      surfaceSectionsStore.sortedSectionIds.forEach((sectionId) => {
        pushSectionPage(sectionId)
        const sortedPostCidsForCurrentSection = surfacePostsStore.sortedPostCidsBySectionId[sectionId]
        sortedPostCidsForCurrentSection?.forEach(pushPostPage)
      })
    } else if (!hasPosts && surfaceSectionsStore.wallHasSections) {
      surfaceSectionsStore.sortedSectionIds.forEach(pushSectionPage)
    } else if (hasPosts && !surfaceSectionsStore.wallHasSections) {
      surfacePostsStore.sortedPostCids.forEach(pushPostPage)
    } else {
      // !hasPosts && !hasSections, do nothing
    }

    return populatedPages
  })
  const currentPageNumber = computed(() => currentPageIndex.value + 1)
  const currentPage = computed<Page | null>(() =>
    pages.value.length > currentPageIndex.value ? pages.value[currentPageIndex.value] : null,
  )
  const currentPost = computed(() => {
    if (currentPage.value?.entityId == null) return null
    return surfacePostsStore.postEntitiesByCid[getCurrentPostCid(currentPage.value.entityId)]
  })
  const currentSection = computed(() => {
    if (currentPage.value?.entityId == null) return null
    return surfaceSectionsStore.getSectionById(Number(currentPage.value?.entityId))
  })

  const currentPageUrl = computed(() => {
    if (currentPage.value == null) return ''

    return buildSlideshowLink(surfaceStore.links.show, currentPage.value.pageType, currentPage.value.entityId)
  })
  const coverPageUrl = computed(() => {
    return buildSlideshowLink(surfaceStore.links.show, PageType.Cover)
  })

  const numberOfPages = computed(() => pages.value.length)
  const lastPageIndex = computed(() => numberOfPages.value - 1)
  const prevPageIndex = computed(() => currentPageIndex.value - 1)
  const nextPageIndex = computed(() => currentPageIndex.value + 1)
  const isFirstPage = computed(() => currentPageIndex.value === COVER_PAGE_INDEX)
  const isLastPage = computed(() => currentPageIndex.value === numberOfPages.value - 1)
  const numberOfWordsOnPage = computed(() => {
    if (currentPage.value?.pageType === PageType.Cover) {
      return getNumberOfWords(surfaceStore.title) + getNumberOfWords(surfaceStore.description)
    } else if (currentPage.value?.pageType === PageType.Section && currentPage.value?.entityId !== undefined) {
      if (currentSection.value == null) {
        return 0
      }
      return getNumberOfWords(currentSection.value.title)
    }

    if (currentPost.value != null) {
      return getNumberOfWords(currentPost.value.body) + getNumberOfWords(currentPost.value.subject)
    }

    return 0
  })

  /*
   * ATTACHMENTS
   */

  const postCidsForLoadedAttachments = ref(new Set<Cid>())
  const areAttachmentsLoadedForCurrentPost = computed(() => {
    if (
      currentPage.value?.pageType === PageType.Post &&
      currentPage.value?.entityId != null &&
      currentPost.value?.attachment != null &&
      currentPost.value?.attachment.length > 0
    ) {
      const currentPostCid = getCurrentPostCid(currentPage.value.entityId)
      return postCidsForLoadedAttachments.value.has(currentPostCid)
    }
    return true
  })
  const numberOfAttachmentsOnPage = computed(() => {
    if (
      currentPage.value?.pageType !== PageType.Post ||
      currentPost.value?.attachment === undefined ||
      currentPost.value?.attachment === ''
    ) {
      return 0
    }
    // at the moment we only support 1 attachment per post
    return 1
  })

  /*
   * Embed
   */

  const slideshowEmbedCodeUrlTemplate = computed<string>(
    () => getVuexStore<RootState>()?.state.constants.slideshowEmbedCodeUrlTemplate,
  )

  return {
    // Data
    isDataLoaded,
    hasLoadedPages,
    pageCoverLoaded,
    isPageCoverLoaded,

    // Custom styles
    shouldRemoveBackground,

    // Menus
    isAnyMenuOpen,
    isAnyOverlayOpen,
    isMoreMenuOpen,
    isSharePanelOpen,
    isAutoplayMenuOpen,
    isShareViaAnotherAppPanelOpen,
    isEmbedPanelOpen,

    // Autoplay
    isAutoplaying,
    isAutoplayPaused,
    isAutoplayLooping,
    selectedAutoplayDuration,
    computedAutoplayDurationInMs,

    // QR Code
    isQrCodeShown,

    // Pages
    pages,
    currentPageIndex,
    currentPost,
    currentSection,
    currentPage,
    currentPageNumber,
    lastPageIndex,
    prevPageIndex,
    nextPageIndex,
    isFirstPage,
    isLastPage,
    numberOfPages,
    numberOfWordsOnPage,
    currentPageUrl,
    coverPageUrl,

    // Attachments
    postCidsForLoadedAttachments,
    areAttachmentsLoadedForCurrentPost,
    numberOfAttachmentsOnPage,

    // Embed
    slideshowEmbedCodeUrlTemplate,
  }
})
